import axios from "axios";
import { omit } from "lodash";

axios.interceptors.request.use((config) => ({ ...config, url: config.url + "?_ts=" + Date.now() }));
axios.interceptors.response.use((response) => (response.config.skipResponseInterceptor ? response : response.data));

export function setHeader(name, value) {
  axios.interceptors.request.use((config) => ({ ...config, headers: { ...config.headers, [name]: value } }));
}

export async function canAccessRoombeltBackend() {
  return getApiVersion().then(
    () => true,
    () => false
  );
}

export async function canAccessBenchmarkSite() {
  return fetch("https://example.com", { mode: "no-cors" }).then(
    () => true,
    () => false
  );
}

export function getAuthUrls() {
  return axios.get("/api/admin/auth_urls");
}

export function getApiVersion() {
  return axios.get("/api/version");
}

export function createDevice() {
  return axios.post("/api/device");
}

export function removeDevice() {
  return axios.delete("/api/device?confirm=true");
}

export function getUserDetails() {
  return axios.get("/api/admin/user");
}

export function getConnectedDevices() {
  return axios.get("/api/admin/device");
}

export function connectDevice(connectionCode) {
  return axios.post("/api/admin/device", { connectionCode });
}

export function reassignDevice(deviceId, connectionCode) {
  return axios.put(`/api/admin/device/${encodeURIComponent(deviceId)}`, { connectionCode });
}

export function setDeviceDisabledState(deviceId, isDisabled, serviceMessage) {
  return axios.put(`/api/admin/device/${encodeURIComponent(deviceId)}`, { isDisabled, serviceMessage });
}

export function disconnectDevice(deviceId) {
  return axios.delete(`/api/admin/device/${encodeURIComponent(deviceId)}`);
}

export function getCalendars() {
  return axios.get("/api/admin/calendar");
}

export function getPermissions() {
  return axios.get("/api/admin/permissions");
}

export function getDeviceDefaults() {
  return axios.get("/api/admin/device-defaults");
}

export function bulkEditDevices(deviceIds, options) {
  return axios.put(`/api/admin/device/bulk`, { deviceIds, ...options });
}

export function setOptionsForDevice(deviceId, options) {
  return axios.put(
    `/api/admin/device/${encodeURIComponent(deviceId)}`,
    omit(options, [
      "createdAt",
      "isOnline",
      "msSinceLastActivity",
      "currentStep",
      "errorMessage",
      "submitButton",
      "meta",
      "deviceSessionToken",
    ])
  );
}

export function setDeviceCustomStyles(deviceId, customStyles) {
  return axios.put(`/api/admin/device/${encodeURIComponent(deviceId)}`, { customStyles });
}

export function setSubscriptionPlan(subscriptionPlanId) {
  return axios.put("/api/admin/subscription", { subscriptionPlanId });
}

export function cancelSubscription() {
  return axios.delete("/api/admin/subscription");
}

export function getAuditLog() {
  return axios.get("/api/admin/audit");
}

export function getDeviceDetails(getAllCalendars) {
  return axios.get("/api/device", {
    params: { "all-calendars": getAllCalendars, width: window.innerWidth, height: window.innerHeight },
  });
}

export function createMeeting(timeInMinutes, summary, calendarId, startTimestamp) {
  return axios.post("/api/device/meeting", { timeInMinutes, summary, calendarId, startTimestamp });
}

export function deleteMeeting(meetingId, isRemovedAutomatically = false, calendarId) {
  return axios.delete(`/api/device/meeting/${encodeURIComponent(meetingId)}`, {
    data: { isRemovedAutomatically, calendarId },
  });
}

export function updateMeeting(meetingId, update) {
  return axios.put(`/api/device/meeting/${encodeURIComponent(meetingId)}`, update);
}

export function reportIssue(issue) {
  return axios.post(`/api/device/issue`, { issue });
}

export function getSignal(id) {
  return axios.get(`/api/signal/${encodeURIComponent(id)}`, { skipResponseInterceptor: true });
}
